/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.5.0
 */
import type { MutationFunction, UseMutationOptions } from 'react-query';
import { useMutation } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthenticateAsMemberBody,
	AuthenticateAsSystemBody,
	AuthenticateResult,
	AuthenticationErrorResult,
	ErrorResult,
	LogOutBody,
	RefreshAccessTokenBody,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Authenticate as a system with an impersonated user.
 */
export const authenticateAsMember = (
	authenticateAsMemberBody: AuthenticateAsMemberBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<AuthenticateResult>(
		{
			url: '/api/authentication/member',
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: authenticateAsMemberBody,
		},
		options,
	);
};

export const getAuthenticateAsMemberMutationOptions = <
	TError = ErrorType<AuthenticationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authenticateAsMember>>,
		TError,
		{ data: AuthenticateAsMemberBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof authenticateAsMember>>,
	TError,
	{ data: AuthenticateAsMemberBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof authenticateAsMember>>,
		{ data: AuthenticateAsMemberBody }
	> = (props) => {
		const { data } = props ?? {};

		return authenticateAsMember(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthenticateAsMemberMutationResult = NonNullable<Awaited<ReturnType<typeof authenticateAsMember>>>;
export type AuthenticateAsMemberMutationBody = AuthenticateAsMemberBody;
export type AuthenticateAsMemberMutationError = ErrorType<AuthenticationErrorResult | ErrorResult>;

/**
 * @summary Authenticate as a system with an impersonated user.
 */
export const useAuthenticateAsMember = <
	TError = ErrorType<AuthenticationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authenticateAsMember>>,
		TError,
		{ data: AuthenticateAsMemberBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getAuthenticateAsMemberMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Authenticate as a system.
 */
export const authenticateAsSystem = (
	authenticateAsSystemBody: AuthenticateAsSystemBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<AuthenticateResult>(
		{
			url: '/api/authentication/system',
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: authenticateAsSystemBody,
		},
		options,
	);
};

export const getAuthenticateAsSystemMutationOptions = <
	TError = ErrorType<AuthenticationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authenticateAsSystem>>,
		TError,
		{ data: AuthenticateAsSystemBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof authenticateAsSystem>>,
	TError,
	{ data: AuthenticateAsSystemBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof authenticateAsSystem>>,
		{ data: AuthenticateAsSystemBody }
	> = (props) => {
		const { data } = props ?? {};

		return authenticateAsSystem(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type AuthenticateAsSystemMutationResult = NonNullable<Awaited<ReturnType<typeof authenticateAsSystem>>>;
export type AuthenticateAsSystemMutationBody = AuthenticateAsSystemBody;
export type AuthenticateAsSystemMutationError = ErrorType<AuthenticationErrorResult | ErrorResult>;

/**
 * @summary Authenticate as a system.
 */
export const useAuthenticateAsSystem = <
	TError = ErrorType<AuthenticationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof authenticateAsSystem>>,
		TError,
		{ data: AuthenticateAsSystemBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getAuthenticateAsSystemMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Refresh the the requested access token. This invalidates the refresh token used and returns a new one
along with the access token.
 */
export const refreshAccessToken = (
	refreshAccessTokenBody: RefreshAccessTokenBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<AuthenticateResult>(
		{
			url: '/api/authentication/refresh',
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: refreshAccessTokenBody,
		},
		options,
	);
};

export const getRefreshAccessTokenMutationOptions = <
	TError = ErrorType<AuthenticationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof refreshAccessToken>>,
		TError,
		{ data: RefreshAccessTokenBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof refreshAccessToken>>,
	TError,
	{ data: RefreshAccessTokenBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof refreshAccessToken>>,
		{ data: RefreshAccessTokenBody }
	> = (props) => {
		const { data } = props ?? {};

		return refreshAccessToken(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type RefreshAccessTokenMutationResult = NonNullable<Awaited<ReturnType<typeof refreshAccessToken>>>;
export type RefreshAccessTokenMutationBody = RefreshAccessTokenBody;
export type RefreshAccessTokenMutationError = ErrorType<AuthenticationErrorResult | ErrorResult>;

/**
 * @summary Refresh the the requested access token. This invalidates the refresh token used and returns a new one
along with the access token.
 */
export const useRefreshAccessToken = <
	TError = ErrorType<AuthenticationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof refreshAccessToken>>,
		TError,
		{ data: RefreshAccessTokenBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getRefreshAccessTokenMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Logs out the user by invalidating the requested refresh token. This only invalidates this specific refresh token.
Other refresh tokens for the same user will still be valid. The access token will be valid until it expires.
 */
export const logOut = (logOutBody: LogOutBody, options?: SecondParameter<typeof crmAxios>) => {
	return crmAxios<void>(
		{
			url: '/api/authentication/logout',
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: logOutBody,
		},
		options,
	);
};

export const getLogOutMutationOptions = <
	TError = ErrorType<AuthenticationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof logOut>>, TError, { data: LogOutBody }, TContext>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<Awaited<ReturnType<typeof logOut>>, TError, { data: LogOutBody }, TContext> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<Awaited<ReturnType<typeof logOut>>, { data: LogOutBody }> = (props) => {
		const { data } = props ?? {};

		return logOut(data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type LogOutMutationResult = NonNullable<Awaited<ReturnType<typeof logOut>>>;
export type LogOutMutationBody = LogOutBody;
export type LogOutMutationError = ErrorType<AuthenticationErrorResult | ErrorResult>;

/**
 * @summary Logs out the user by invalidating the requested refresh token. This only invalidates this specific refresh token.
Other refresh tokens for the same user will still be valid. The access token will be valid until it expires.
 */
export const useLogOut = <TError = ErrorType<AuthenticationErrorResult | ErrorResult>, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<Awaited<ReturnType<typeof logOut>>, TError, { data: LogOutBody }, TContext>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getLogOutMutationOptions(options);

	return useMutation(mutationOptions);
};

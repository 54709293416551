/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.5.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	DocumentDto,
	ErrorResult,
	GetMemberDocumentsByFilterParams,
	MemberUnreadDocumentsResponse,
	PagingResultOfDocumentDto,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get all documents for the specified member.
 */
export const getMemberDocumentsByFilter = (
	memberId: string,
	params?: GetMemberDocumentsByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfDocumentDto>(
		{ url: `/api/members/${memberId}/documents`, method: 'get', params, signal },
		options,
	);
};

export const getGetMemberDocumentsByFilterQueryKey = (memberId: string, params?: GetMemberDocumentsByFilterParams) =>
	[`/api/members/${memberId}/documents`, ...(params ? [params] : [])] as const;

export const getGetMemberDocumentsByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberDocumentsByFilter>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberDocumentsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberDocumentsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberDocumentsByFilter>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberDocumentsByFilterQueryKey(memberId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberDocumentsByFilter>>> = ({ signal }) =>
		getMemberDocumentsByFilter(memberId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberDocumentsByFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberDocumentsByFilter>>>;
export type GetMemberDocumentsByFilterQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get all documents for the specified member.
 */
export const useGetMemberDocumentsByFilter = <
	TData = Awaited<ReturnType<typeof getMemberDocumentsByFilter>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberDocumentsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberDocumentsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberDocumentsByFilterQueryOptions(memberId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get the specific document for the member.
 */
export const getMemberDocumentsById = (
	memberId: string,
	documentId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<DocumentDto>(
		{ url: `/api/members/${memberId}/documents/${documentId}`, method: 'get', signal },
		options,
	);
};

export const getGetMemberDocumentsByIdQueryKey = (memberId: string, documentId: string) =>
	[`/api/members/${memberId}/documents/${documentId}`] as const;

export const getGetMemberDocumentsByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberDocumentsById>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	documentId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberDocumentsById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberDocumentsById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberDocumentsByIdQueryKey(memberId, documentId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberDocumentsById>>> = ({ signal }) =>
		getMemberDocumentsById(memberId, documentId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(memberId && documentId), staleTime: 10000, ...queryOptions };
};

export type GetMemberDocumentsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberDocumentsById>>>;
export type GetMemberDocumentsByIdQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get the specific document for the member.
 */
export const useGetMemberDocumentsById = <
	TData = Awaited<ReturnType<typeof getMemberDocumentsById>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	documentId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberDocumentsById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberDocumentsByIdQueryOptions(memberId, documentId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get number of unread akasse documents.
 */
export const getMemberUnreadDocumentsCount = (
	memberId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<MemberUnreadDocumentsResponse>(
		{ url: `/api/members/${memberId}/documents/unreadcount`, method: 'get', signal },
		options,
	);
};

export const getGetMemberUnreadDocumentsCountQueryKey = (memberId: string) =>
	[`/api/members/${memberId}/documents/unreadcount`] as const;

export const getGetMemberUnreadDocumentsCountQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberUnreadDocumentsCount>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberUnreadDocumentsCount>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberUnreadDocumentsCount>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberUnreadDocumentsCountQueryKey(memberId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberUnreadDocumentsCount>>> = ({ signal }) =>
		getMemberUnreadDocumentsCount(memberId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberUnreadDocumentsCountQueryResult = NonNullable<
	Awaited<ReturnType<typeof getMemberUnreadDocumentsCount>>
>;
export type GetMemberUnreadDocumentsCountQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get number of unread akasse documents.
 */
export const useGetMemberUnreadDocumentsCount = <
	TData = Awaited<ReturnType<typeof getMemberUnreadDocumentsCount>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberUnreadDocumentsCount>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberUnreadDocumentsCountQueryOptions(memberId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get number of unread faglige documents.
 */
export const getMemberUnreadUnionDocumentsCount = (
	memberId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<MemberUnreadDocumentsResponse>(
		{ url: `/api/members/${memberId}/documents/union/unreadcount`, method: 'get', signal },
		options,
	);
};

export const getGetMemberUnreadUnionDocumentsCountQueryKey = (memberId: string) =>
	[`/api/members/${memberId}/documents/union/unreadcount`] as const;

export const getGetMemberUnreadUnionDocumentsCountQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberUnreadUnionDocumentsCount>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberUnreadUnionDocumentsCount>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberUnreadUnionDocumentsCount>>, TError, TData> & {
	queryKey: QueryKey;
} => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberUnreadUnionDocumentsCountQueryKey(memberId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberUnreadUnionDocumentsCount>>> = ({ signal }) =>
		getMemberUnreadUnionDocumentsCount(memberId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberUnreadUnionDocumentsCountQueryResult = NonNullable<
	Awaited<ReturnType<typeof getMemberUnreadUnionDocumentsCount>>
>;
export type GetMemberUnreadUnionDocumentsCountQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get number of unread faglige documents.
 */
export const useGetMemberUnreadUnionDocumentsCount = <
	TData = Awaited<ReturnType<typeof getMemberUnreadUnionDocumentsCount>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberUnreadUnionDocumentsCount>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberUnreadUnionDocumentsCountQueryOptions(memberId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

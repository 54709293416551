/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.5.0
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	PaymentMethodResponse,
	PaymentSessionResponse,
	PutPaymentSessionParams,
	PutPbsPaymentParams,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get a specified members payment method
 */
export const getMemberPaymentForm = (
	memberId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PaymentMethodResponse>({ url: `/api/members/${memberId}/payments`, method: 'get', signal }, options);
};

export const getGetMemberPaymentFormQueryKey = (memberId: string) => [`/api/members/${memberId}/payments`] as const;

export const getGetMemberPaymentFormQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberPaymentForm>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberPaymentForm>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberPaymentForm>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberPaymentFormQueryKey(memberId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberPaymentForm>>> = ({ signal }) =>
		getMemberPaymentForm(memberId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberPaymentFormQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberPaymentForm>>>;
export type GetMemberPaymentFormQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get a specified members payment method
 */
export const useGetMemberPaymentForm = <
	TData = Awaited<ReturnType<typeof getMemberPaymentForm>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberPaymentForm>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberPaymentFormQueryOptions(memberId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Create a new PBS aftale for member
 */
export const putPbsPayment = (
	memberId: string,
	params: PutPbsPaymentParams,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<void>({ url: `/api/members/${memberId}/payments/pbs-payment`, method: 'put', params }, options);
};

export const getPutPbsPaymentMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putPbsPayment>>,
		TError,
		{ memberId: string; params: PutPbsPaymentParams },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof putPbsPayment>>,
	TError,
	{ memberId: string; params: PutPbsPaymentParams },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof putPbsPayment>>,
		{ memberId: string; params: PutPbsPaymentParams }
	> = (props) => {
		const { memberId, params } = props ?? {};

		return putPbsPayment(memberId, params, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PutPbsPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof putPbsPayment>>>;

export type PutPbsPaymentMutationError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Create a new PBS aftale for member
 */
export const usePutPbsPayment = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putPbsPayment>>,
		TError,
		{ memberId: string; params: PutPbsPaymentParams },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getPutPbsPaymentMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Returns A URL, to a payment method session in moduluspayments.
 */
export const putPaymentSession = (
	memberId: string,
	params: PutPaymentSessionParams,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<PaymentSessionResponse>(
		{ url: `/api/members/${memberId}/payments/payment-session`, method: 'put', params },
		options,
	);
};

export const getPutPaymentSessionMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putPaymentSession>>,
		TError,
		{ memberId: string; params: PutPaymentSessionParams },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof putPaymentSession>>,
	TError,
	{ memberId: string; params: PutPaymentSessionParams },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof putPaymentSession>>,
		{ memberId: string; params: PutPaymentSessionParams }
	> = (props) => {
		const { memberId, params } = props ?? {};

		return putPaymentSession(memberId, params, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PutPaymentSessionMutationResult = NonNullable<Awaited<ReturnType<typeof putPaymentSession>>>;

export type PutPaymentSessionMutationError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Returns A URL, to a payment method session in moduluspayments.
 */
export const usePutPaymentSession = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putPaymentSession>>,
		TError,
		{ memberId: string; params: PutPaymentSessionParams },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getPutPaymentSessionMutationOptions(options);

	return useMutation(mutationOptions);
};

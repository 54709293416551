export const validationMessages = {
	general_error: {
		validation: 'Der skete en fejl. Prøv igen senere.',
		api: '',
	},
	first_name: {
		empty: 'Oplys venligst dit fornavn',
		error: 'Fornavn skal være mellem 2-50 tegn',
	},
	last_name: {
		empty: 'Oplys venligst dit efternavn',
		error: 'Efternavn skal være mellem 2-50 tegn',
	},
	phone_number: {
		empty: 'Oplys venligst dit telefonnummer',
		error: 'Telefonnummer skal være mellem 6-20 cifre',
	},
	email: {
		empty: 'Oplys venligst din e-mail',
		error: 'Email skal have et gyldigt domæne',
	},
	contact: {
		empty: 'Vælg venligst, hvem du vil kontakte',
	},
	message: {
		error: 'Beskeden må maks. være 1000 tegn',
	},
	files: {
		error: 'Filen skal være PDF, DOCX eller DOC, maks. 20 MB og titlen må være maks. 50 tegn',
	},
	subjects: {
		empty: 'Vælg venligst, hvad din henvendelse handler om',
	},
	time_intervals: {
		empty: 'Vælg venligst, hvornår du vil ringes op',
	},
	payment_reg_nr: {
		empty: 'Oplys venligst dit reg. nr. ',
		error: 'Reg. nr. skal være 4 cifre',
	},
	payment_konto_nr: {
		empty: 'Oplys venligst dit kontonr.',
		error: 'Kontonr. skal være 10 cifre',
	},
	salary: {
		empty: 'Oplys venligst din timeløn',
		error: 'Timeløn må kun indeholde tal og sepereres med komma',
	},
	end_date: {
		empty: 'Oplys venligst hvornår du er udlært',
	},
	education: {
		empty: 'Vælg venligst, hvilken uddannelse du er i gang med',
		error: 'Vælg venligst en uddannelse fra listen',
	},
	region: {
		empty: 'Vælg venligst, hvor din arbejdsplads ligger',
	},
	profession: {
		empty: 'Vælg venligst, hvilken fagruppe du tilhører',
		error: 'Vælg venligst en fagruppe fra listen',
	},
	workplace: {
		error: 'Arbejdsgiveren er ukendt for Dansk Metal - oplys venligst adresse, postnr., og din ansættelsesdato herunder',
	},
	workplace_address: {
		empty: 'Oplys venligst arbejdsgiveres adresse',
		error: 'Adressen skal være mellem 3-160 tegn',
	},
	workplace_zip_code: {
		empty: 'Oplys venligst arbejdsgiveres postnr.',
		error: 'Postnr. skal være mellem 1000-9999',
	},
	department: {
		empty: 'Vælg venligst din lokalafdeling',
		error: 'Vælg venligst en lokalafdeling fra listen',
	},
	new_education: {
		empty: 'Vælg venligst din nuværende uddannelse',
		error: 'Vælg venligst en uddannelse fra listen',
	},
	startDate: {
		empty: 'Oplys venligst din ansættelsesdato',
		error: 'Ansættelsesdato skal være en gyldig dato',
	},
	birhtday: {
		empty: 'Oplys venligst din fødselsdato',
		error: 'Fødselsdato skal være en gyldig dato',
	},
};

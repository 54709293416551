import { CError, Info, Options } from '@jdpnielsen/contextual-error';

import { ErrorMessages, ErrorType } from './errorTypes';

export const APP_ERROR_SYMBOL = Symbol.for('error/app');

export type AppErrorOptions = Omit<Options, 'name'> & { name?: ErrorType };

export class AppError<E extends {} = {}> extends CError {
	public readonly name: ErrorType = ErrorType.App;
	readonly info: Info & E;

	constructor(message: string, cause?: Error, options?: AppErrorOptions) {
		super(message, cause, options);
		Object.defineProperty(this, APP_ERROR_SYMBOL, { value: true });

		if (options?.name) {
			this.name = options.name;
		}
	}

	public static isAppError(obj: unknown): obj is AppError {
		return (obj as { [APP_ERROR_SYMBOL]?: boolean })?.[APP_ERROR_SYMBOL] != null;
	}

	public static isErrorType(obj: unknown, type: ErrorType): obj is AppError {
		return AppError.isAppError(obj) && obj.name === type;
	}
}

export function appError({ message, cause, info }: { message?: string; cause?: Error; info?: Info } = {}): AppError {
	const errorMessage = message || ErrorMessages[ErrorType.App];

	return new AppError(
		errorMessage,
		cause,
		{
			name: ErrorType.App,
			constructorOpt: appError,
			info,
		},
	);
}

export function umbracoFetchError({ message, cause, info }: { message?: string; cause?: Error; info?: Info } = { }): AppError {
	const errorMessage = message || ErrorMessages[ErrorType.UmbracoFetch];
	const errorInfo = info || {};

	return new AppError(
		errorMessage,
		cause,
		{
			name: ErrorType.UmbracoFetch,
			constructorOpt: umbracoFetchError,
			info: errorInfo,
		},
	);
}

export function crmError({ message, cause, info }: { message?: string; cause?: Error; info?: Info } = {}): AppError {
	const errorMessage = message || ErrorMessages[ErrorType.CRM];

	return new AppError(
		errorMessage,
		cause,
		{
			name: ErrorType.CRM,
			constructorOpt: crmError,
			info,
		},
	);
}

import { Info } from '@jdpnielsen/contextual-error';
import * as Sentry from '@sentry/nextjs';
import { CombinedError } from 'urql/core';

import { AppError } from './appError';
import { ErrorMessages, ErrorType } from './errorTypes';

export function reportException(
	{ message, context, cause }: { message?: string; context?: Info; cause?: Error | AppError } = {},
) {
	const ex = AppError.isAppError(cause)
		? cause
		: new AppError(message || ErrorMessages[ErrorType.App], cause, { constructorOpt: reportException });

	const err: Error & { info?: string } = new Error(ex.shortMessage, { cause });
	err.name = ex.name;
	err.stack = AppError.fullStack(ex);
	const info = AppError.info(ex);
	const combinedError = AppError.findCauseByName(ex, 'CombinedError');

	if (combinedError instanceof CombinedError) {
		if (combinedError.graphQLErrors.length !== 0) {
			info['graphQLErrors'] = combinedError.graphQLErrors;
		}

		if (combinedError.networkError) {
			err.cause = combinedError.networkError;
		}
	}

	const eventId = Sentry.captureException(err, {
		extra: {
			...info,
			...context,
		},
	});

	// eslint-disable-next-line no-console
	console.dir({
		message,
		...(context ? { context } : {}),
		error: err,
		...(info ? { info } : {}),
		eventId,
	}, {
		depth: null,
		colors: true,
	});

	return eventId;
}

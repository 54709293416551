export enum AspectRatio {
	'16:9' = 16 / 9,
	'12:5' = 12 / 5,
	'4:5' = 4 / 5,
	'4:3' = 4 / 3,
	'2:1' = 2 / 1,
	'1:1' = 1 / 1,
}

export enum Aspect {
	'16:9' = '16:9',
	'12:5' = '12:5',
	'4:5' = '4:5',
	'4:3' = '4:3',
	'2:1' = '2:1',
	'1:1' = '1:1',
}

import { useEffect, useState } from 'react';
import { ErrorBoundary } from '@sentry/nextjs';

import { MacroParameter, MacroParameterProps } from '@web/components/umbraco-macro/macros/macro-parameter';

import { AppError } from '@web/services/errorhandler/appError';

import styles from './umbraco-macro.module.scss';

export interface UmbracoMacroProps {
	alias: string;
	data: string;
}

export function UmbracoMacro({ alias, data }: UmbracoMacroProps) {
	return (
		<ErrorBoundary fallback={(errData) => {
			const err = errData.error;

			if (AppError.isAppError(err)) {
				return <span className={styles.error}>&#123;{errData.error.message}&#125;</span>;
			}

			throw errData.error;
		}}>
			<MacroRenderer alias={alias} data={data} />
		</ErrorBoundary>
	);
}

function MacroRenderer({ alias, data }: UmbracoMacroProps) {
	// Convert json string to object
	const [macroData, setMacroData] = useState(JSON.parse(data));

	useEffect(() => {
		setMacroData(JSON.parse(data));
	}, [data]);

	if (alias === 'macroParameter') {
		return <MacroParameter data={macroData as MacroParameterProps['data']} />;
	}

	throw new AppError(`Unknown macro: "${alias}"`, undefined, {
		info: {
			alias,
			data,
		},
	});
}

import { useEffect, useState } from 'react';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { Return } from 'ts-toolbelt/out/Function/Return';

import { AppError, crmError } from '@web/services/errorhandler/appError';

type RequestConfig = { baseURL: string };

type UseAuthenticatedCRMRequestParams<T> = {
	request: (session: Session, config: RequestConfig) => Promise<T>;
};

export function useAuthenticatedCRMRequest<T>({ request }: UseAuthenticatedCRMRequestParams<T>) {
	const [isLoading, setIsloading] = useState(false);
	const [isLoaded, setIsloaded] = useState(false);
	const [error, setError] = useState<AppError | null>(null);
	const [loadedData, setLoadedData] = useState<T | null>(null);
	const { status, data, requestConfig } = useAuthenticatedCRMRequestConfig();

	const fetchData = async () => {
		if (status === 'authenticated' && !isLoaded && !isLoading) {
			setIsloading(true);

			request(data, requestConfig)
				.then((responseData) => {
					setLoadedData(responseData);
					setIsloaded(true);
					setIsloading(false);
				})
				.catch((err) => {
					setIsloaded(true);
					setIsloading(false);
					const ex = crmError({
						message: 'Error loading authenticated request',
						cause: err,
						info: {
							user: data.user,
						},
					});

					setError(ex);
				});
		}
	};

	useEffect(() => {
		fetchData();
	}, [data, data?.user, isLoaded, isLoading, request, status]);

	return {
		data: loadedData,
		refetch: fetchData,
		error,
		isLoading,
		isLoaded,
	};
}

type UseAuthenticatedCRMRequestConfigReturn = Return<typeof useSession> & {
	requestConfig: RequestConfig;
	memberId: string;
};

export function useAuthenticatedCRMRequestConfig(): UseAuthenticatedCRMRequestConfigReturn {
	const session = useSession();

	return {
		...session,
		memberId: session?.data?.user?.memberId || '',
		requestConfig: {
			baseURL: '/api/crm',
		},
	};
}

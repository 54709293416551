/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.5.0
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	GetMemberTasksByFilterParams,
	TaskDto,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Get all tasks for the specified member.
 */
export const getMemberTasksByFilter = (
	memberId: string,
	params?: GetMemberTasksByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<TaskDto[]>({ url: `/api/members/${memberId}/tasks`, method: 'get', params, signal }, options);
};

export const getGetMemberTasksByFilterQueryKey = (memberId: string, params?: GetMemberTasksByFilterParams) =>
	[`/api/members/${memberId}/tasks`, ...(params ? [params] : [])] as const;

export const getGetMemberTasksByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberTasksByFilter>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberTasksByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberTasksByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberTasksByFilter>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberTasksByFilterQueryKey(memberId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberTasksByFilter>>> = ({ signal }) =>
		getMemberTasksByFilter(memberId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberTasksByFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberTasksByFilter>>>;
export type GetMemberTasksByFilterQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get all tasks for the specified member.
 */
export const useGetMemberTasksByFilter = <
	TData = Awaited<ReturnType<typeof getMemberTasksByFilter>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberTasksByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberTasksByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberTasksByFilterQueryOptions(memberId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Get the specific task for the member.
 */
export const getMemberTaskById = (
	memberId: string,
	taskId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<TaskDto>({ url: `/api/members/${memberId}/tasks/${taskId}`, method: 'get', signal }, options);
};

export const getGetMemberTaskByIdQueryKey = (memberId: string, taskId: string) =>
	[`/api/members/${memberId}/tasks/${taskId}`] as const;

export const getGetMemberTaskByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberTaskById>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	taskId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberTaskById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberTaskById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberTaskByIdQueryKey(memberId, taskId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberTaskById>>> = ({ signal }) =>
		getMemberTaskById(memberId, taskId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(memberId && taskId), staleTime: 10000, ...queryOptions };
};

export type GetMemberTaskByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberTaskById>>>;
export type GetMemberTaskByIdQueryError = ErrorType<AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Get the specific task for the member.
 */
export const useGetMemberTaskById = <
	TData = Awaited<ReturnType<typeof getMemberTaskById>>,
	TError = ErrorType<AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	taskId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberTaskById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberTaskByIdQueryOptions(memberId, taskId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Marks the task for a specific member as complete.
 */
export const markMemberTaskCompleted = (
	memberId: string,
	taskId: string,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<TaskDto>({ url: `/api/members/${memberId}/tasks/${taskId}`, method: 'put' }, options);
};

export const getMarkMemberTaskCompletedMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof markMemberTaskCompleted>>,
		TError,
		{ memberId: string; taskId: string },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof markMemberTaskCompleted>>,
	TError,
	{ memberId: string; taskId: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof markMemberTaskCompleted>>,
		{ memberId: string; taskId: string }
	> = (props) => {
		const { memberId, taskId } = props ?? {};

		return markMemberTaskCompleted(memberId, taskId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type MarkMemberTaskCompletedMutationResult = NonNullable<Awaited<ReturnType<typeof markMemberTaskCompleted>>>;

export type MarkMemberTaskCompletedMutationError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Marks the task for a specific member as complete.
 */
export const useMarkMemberTaskCompleted = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof markMemberTaskCompleted>>,
		TError,
		{ memberId: string; taskId: string },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getMarkMemberTaskCompletedMutationOptions(options);

	return useMutation(mutationOptions);
};

/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.5.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	EmployeeDto,
	ErrorResult,
	GetEmployeeByFilterParams,
	PagingResultOfEmployeeDto,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves all employees.
 */
export const getEmployeeByFilter = (
	params?: GetEmployeeByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfEmployeeDto>({ url: '/api/employees', method: 'get', params, signal }, options);
};

export const getGetEmployeeByFilterQueryKey = (params?: GetEmployeeByFilterParams) =>
	['/api/employees', ...(params ? [params] : [])] as const;

export const getGetEmployeeByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getEmployeeByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetEmployeeByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEmployeeByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getEmployeeByFilter>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetEmployeeByFilterQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmployeeByFilter>>> = ({ signal }) =>
		getEmployeeByFilter(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetEmployeeByFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getEmployeeByFilter>>>;
export type GetEmployeeByFilterQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves all employees.
 */
export const useGetEmployeeByFilter = <
	TData = Awaited<ReturnType<typeof getEmployeeByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetEmployeeByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEmployeeByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetEmployeeByFilterQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves a specific employee.
 */
export const getEmployeeById = (
	employeeId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<EmployeeDto>({ url: `/api/employees/${employeeId}`, method: 'get', signal }, options);
};

export const getGetEmployeeByIdQueryKey = (employeeId: string) => [`/api/employees/${employeeId}`] as const;

export const getGetEmployeeByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getEmployeeById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	employeeId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEmployeeById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getEmployeeById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetEmployeeByIdQueryKey(employeeId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmployeeById>>> = ({ signal }) =>
		getEmployeeById(employeeId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!employeeId, staleTime: 10000, ...queryOptions };
};

export type GetEmployeeByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEmployeeById>>>;
export type GetEmployeeByIdQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves a specific employee.
 */
export const useGetEmployeeById = <
	TData = Awaited<ReturnType<typeof getEmployeeById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	employeeId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getEmployeeById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetEmployeeByIdQueryOptions(employeeId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves a list of unique departments assigned to the employees.
 */
export const getUniqueDepartmentNames = (options?: SecondParameter<typeof crmAxios>, signal?: AbortSignal) => {
	return crmAxios<string[]>({ url: '/api/employees/phonebook/departments', method: 'get', signal }, options);
};

export const getGetUniqueDepartmentNamesQueryKey = () => ['/api/employees/phonebook/departments'] as const;

export const getGetUniqueDepartmentNamesQueryOptions = <
	TData = Awaited<ReturnType<typeof getUniqueDepartmentNames>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getUniqueDepartmentNames>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryOptions<Awaited<ReturnType<typeof getUniqueDepartmentNames>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetUniqueDepartmentNamesQueryKey();

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getUniqueDepartmentNames>>> = ({ signal }) =>
		getUniqueDepartmentNames(requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetUniqueDepartmentNamesQueryResult = NonNullable<Awaited<ReturnType<typeof getUniqueDepartmentNames>>>;
export type GetUniqueDepartmentNamesQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Retrieves a list of unique departments assigned to the employees.
 */
export const useGetUniqueDepartmentNames = <
	TData = Awaited<ReturnType<typeof getUniqueDepartmentNames>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getUniqueDepartmentNames>>, TError, TData>;
	request?: SecondParameter<typeof crmAxios>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetUniqueDepartmentNamesQueryOptions(options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.5.0
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseQueryOptions,
	UseQueryResult,
} from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	EventDto,
	EventsResponse,
	GetMemberEventsByFilterParams,
	PostMemberEventRegistrationBody,
	PutMemberEventRegistrationBody,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves all events, with member context.
This can optionally be filtered by type, group and Department.
Optionally a string can be used to query title, intro and body text of events
Uses pagination through limit and offset.
 */
export const getMemberEventsByFilter = (
	memberId: string,
	params?: GetMemberEventsByFilterParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<EventsResponse>({ url: `/api/members/${memberId}/events`, method: 'get', params, signal }, options);
};

export const getGetMemberEventsByFilterQueryKey = (memberId: string, params?: GetMemberEventsByFilterParams) =>
	[`/api/members/${memberId}/events`, ...(params ? [params] : [])] as const;

export const getGetMemberEventsByFilterQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberEventsByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberEventsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberEventsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberEventsByFilter>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberEventsByFilterQueryKey(memberId, params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberEventsByFilter>>> = ({ signal }) =>
		getMemberEventsByFilter(memberId, params, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!memberId, staleTime: 10000, ...queryOptions };
};

export type GetMemberEventsByFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberEventsByFilter>>>;
export type GetMemberEventsByFilterQueryError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Retrieves all events, with member context.
This can optionally be filtered by type, group and Department.
Optionally a string can be used to query title, intro and body text of events
Uses pagination through limit and offset.
 */
export const useGetMemberEventsByFilter = <
	TData = Awaited<ReturnType<typeof getMemberEventsByFilter>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	params?: GetMemberEventsByFilterParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberEventsByFilter>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberEventsByFilterQueryOptions(memberId, params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves a specific event, with member context.
 */
export const getMemberEventsById = (
	memberId: string,
	eventId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<EventDto>({ url: `/api/members/${memberId}/events/${eventId}`, method: 'get', signal }, options);
};

export const getGetMemberEventsByIdQueryKey = (memberId: string, eventId: string) =>
	[`/api/members/${memberId}/events/${eventId}`] as const;

export const getGetMemberEventsByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getMemberEventsById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	eventId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberEventsById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getMemberEventsById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetMemberEventsByIdQueryKey(memberId, eventId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberEventsById>>> = ({ signal }) =>
		getMemberEventsById(memberId, eventId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!(memberId && eventId), staleTime: 10000, ...queryOptions };
};

export type GetMemberEventsByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberEventsById>>>;
export type GetMemberEventsByIdQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves a specific event, with member context.
 */
export const useGetMemberEventsById = <
	TData = Awaited<ReturnType<typeof getMemberEventsById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	memberId: string,
	eventId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getMemberEventsById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetMemberEventsByIdQueryOptions(memberId, eventId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Register member for a specific event.
 */
export const postMemberEventRegistration = (
	memberId: string,
	eventId: string,
	postMemberEventRegistrationBody: PostMemberEventRegistrationBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<void>(
		{
			url: `/api/members/${memberId}/events/${eventId}`,
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			data: postMemberEventRegistrationBody,
		},
		options,
	);
};

export const getPostMemberEventRegistrationMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof postMemberEventRegistration>>,
		TError,
		{ memberId: string; eventId: string; data: PostMemberEventRegistrationBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof postMemberEventRegistration>>,
	TError,
	{ memberId: string; eventId: string; data: PostMemberEventRegistrationBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof postMemberEventRegistration>>,
		{ memberId: string; eventId: string; data: PostMemberEventRegistrationBody }
	> = (props) => {
		const { memberId, eventId, data } = props ?? {};

		return postMemberEventRegistration(memberId, eventId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PostMemberEventRegistrationMutationResult = NonNullable<
	Awaited<ReturnType<typeof postMemberEventRegistration>>
>;
export type PostMemberEventRegistrationMutationBody = PostMemberEventRegistrationBody;
export type PostMemberEventRegistrationMutationError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Register member for a specific event.
 */
export const usePostMemberEventRegistration = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof postMemberEventRegistration>>,
		TError,
		{ memberId: string; eventId: string; data: PostMemberEventRegistrationBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getPostMemberEventRegistrationMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Alters members registration for a specific event.
 */
export const putMemberEventRegistration = (
	memberId: string,
	eventId: string,
	putMemberEventRegistrationBody: PutMemberEventRegistrationBody,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<string>(
		{
			url: `/api/members/${memberId}/events/${eventId}`,
			method: 'put',
			headers: { 'Content-Type': 'application/json' },
			data: putMemberEventRegistrationBody,
		},
		options,
	);
};

export const getPutMemberEventRegistrationMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putMemberEventRegistration>>,
		TError,
		{ memberId: string; eventId: string; data: PutMemberEventRegistrationBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof putMemberEventRegistration>>,
	TError,
	{ memberId: string; eventId: string; data: PutMemberEventRegistrationBody },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof putMemberEventRegistration>>,
		{ memberId: string; eventId: string; data: PutMemberEventRegistrationBody }
	> = (props) => {
		const { memberId, eventId, data } = props ?? {};

		return putMemberEventRegistration(memberId, eventId, data, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type PutMemberEventRegistrationMutationResult = NonNullable<
	Awaited<ReturnType<typeof putMemberEventRegistration>>
>;
export type PutMemberEventRegistrationMutationBody = PutMemberEventRegistrationBody;
export type PutMemberEventRegistrationMutationError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Alters members registration for a specific event.
 */
export const usePutMemberEventRegistration = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof putMemberEventRegistration>>,
		TError,
		{ memberId: string; eventId: string; data: PutMemberEventRegistrationBody },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getPutMemberEventRegistrationMutationOptions(options);

	return useMutation(mutationOptions);
};
/**
 * @summary Unregistered the member from a specific event
 */
export const deleteMemberEventRegistration = (
	memberId: string,
	eventId: string,
	options?: SecondParameter<typeof crmAxios>,
) => {
	return crmAxios<void>({ url: `/api/members/${memberId}/events/${eventId}`, method: 'delete' }, options);
};

export const getDeleteMemberEventRegistrationMutationOptions = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof deleteMemberEventRegistration>>,
		TError,
		{ memberId: string; eventId: string },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}): UseMutationOptions<
	Awaited<ReturnType<typeof deleteMemberEventRegistration>>,
	TError,
	{ memberId: string; eventId: string },
	TContext
> => {
	const { mutation: mutationOptions, request: requestOptions } = options ?? {};

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof deleteMemberEventRegistration>>,
		{ memberId: string; eventId: string }
	> = (props) => {
		const { memberId, eventId } = props ?? {};

		return deleteMemberEventRegistration(memberId, eventId, requestOptions);
	};

	return { mutationFn, ...mutationOptions };
};

export type DeleteMemberEventRegistrationMutationResult = NonNullable<
	Awaited<ReturnType<typeof deleteMemberEventRegistration>>
>;

export type DeleteMemberEventRegistrationMutationError = ErrorType<
	ValidationErrorResult | AuthorizationErrorResult | ErrorResult
>;

/**
 * @summary Unregistered the member from a specific event
 */
export const useDeleteMemberEventRegistration = <
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof deleteMemberEventRegistration>>,
		TError,
		{ memberId: string; eventId: string },
		TContext
	>;
	request?: SecondParameter<typeof crmAxios>;
}) => {
	const mutationOptions = getDeleteMemberEventRegistrationMutationOptions(options);

	return useMutation(mutationOptions);
};

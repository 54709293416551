/**
 * Generated by orval v6.16.0 🍺
 * Do not edit manually.
 * Dansk Metal Unified API
 * Unified REST API for Dansk Metal internal and 3rd party clients.
 * OpenAPI spec version: 1.5.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from 'react-query';
import { useQuery } from 'react-query';

import type { ErrorType } from '../axios';
import { crmAxios } from '../axios';
import type {
	AuthorizationErrorResult,
	ErrorResult,
	GetPositionsParams,
	PagingResultOfPositionDto,
	PositionDto,
	ValidationErrorResult,
} from '../crm-api.schemas';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never;

/**
 * @summary Retrieves all positions.
 */
export const getPositions = (
	params?: GetPositionsParams,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PagingResultOfPositionDto>({ url: '/api/positions', method: 'get', params, signal }, options);
};

export const getGetPositionsQueryKey = (params?: GetPositionsParams) =>
	['/api/positions', ...(params ? [params] : [])] as const;

export const getGetPositionsQueryOptions = <
	TData = Awaited<ReturnType<typeof getPositions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetPositionsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getPositions>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getPositions>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetPositionsQueryKey(params);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getPositions>>> = ({ signal }) =>
		getPositions(params, requestOptions, signal);

	return { queryKey, queryFn, staleTime: 10000, ...queryOptions };
};

export type GetPositionsQueryResult = NonNullable<Awaited<ReturnType<typeof getPositions>>>;
export type GetPositionsQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves all positions.
 */
export const useGetPositions = <
	TData = Awaited<ReturnType<typeof getPositions>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	params?: GetPositionsParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getPositions>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetPositionsQueryOptions(params, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};

/**
 * @summary Retrieves a specific position.
 */
export const getPositionById = (
	positionId: string,
	options?: SecondParameter<typeof crmAxios>,
	signal?: AbortSignal,
) => {
	return crmAxios<PositionDto>({ url: `/api/positions/${positionId}`, method: 'get', signal }, options);
};

export const getGetPositionByIdQueryKey = (positionId: string) => [`/api/positions/${positionId}`] as const;

export const getGetPositionByIdQueryOptions = <
	TData = Awaited<ReturnType<typeof getPositionById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	positionId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getPositionById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryOptions<Awaited<ReturnType<typeof getPositionById>>, TError, TData> & { queryKey: QueryKey } => {
	const { query: queryOptions, request: requestOptions } = options ?? {};
	const queryKey = queryOptions?.queryKey ?? getGetPositionByIdQueryKey(positionId);

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getPositionById>>> = ({ signal }) =>
		getPositionById(positionId, requestOptions, signal);

	return { queryKey, queryFn, enabled: !!positionId, staleTime: 10000, ...queryOptions };
};

export type GetPositionByIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPositionById>>>;
export type GetPositionByIdQueryError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>;

/**
 * @summary Retrieves a specific position.
 */
export const useGetPositionById = <
	TData = Awaited<ReturnType<typeof getPositionById>>,
	TError = ErrorType<ValidationErrorResult | AuthorizationErrorResult | ErrorResult>,
>(
	positionId: string,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<typeof getPositionById>>, TError, TData>;
		request?: SecondParameter<typeof crmAxios>;
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetPositionByIdQueryOptions(positionId, options);
	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryOptions.queryKey;

	return query;
};
